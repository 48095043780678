import ContactForm from '../components/ContactForm';

import photo1 from '../images/medical-11.webp';
import logo from '../images/logo-white.webp';

export default function ContactPage() {

    const vertical = "healthcare";

    return (
        <div className="bg-white">

            {/* HERO */}
            <div className="relative isolate overflow-hidden bg-teal-950 min-h-screen flex items-center">
                <img
                    src={photo1}
                    alt={vertical}
                    draggable="false"
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-bottom sm:object-left opacity-10"
                />
                <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
                        <a href="/">
                            <img
                                alt="Janie Health"
                                src={logo}
                                draggable="false"
                                className="w-32 h-auto pb-40"
                            />
                        </a>

                        <h1 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                            Optimize your practice with virtual {vertical} assistants
                        </h1>
                        <p className="mt-6 text-2xl leading-8 text-gray-200">
                            Janie helps {vertical} practices reduce costs and enhance efficiency.
                        </p>
                    </div>
                    <div className="mx-auto mt-8 flex max-w-full sm:mt-16 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                        <div className="w-full flex-none sm:max-w-5xl lg:max-w-none">
                            <div className="-m-2 rounded-lg bg-white/95 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl px-4 py-8 mt-12 sm:p-12">
                                {/* CONTACT */}
                                <ContactForm
                                    title="Get in touch"
                                    description="Fill out the form and we'll reach out soon."
                                    source="Contact Page Lead Form"
                                    lead={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}