import { ComputerDesktopIcon, PhoneArrowUpRightIcon, BanknotesIcon, PencilIcon } from '@heroicons/react/24/outline'
import MarketingPage from '../components/MarketingPage';

import dog2 from '../images/dog6.webp';
import dog from '../images/dog7.webp';
import vet1 from '../images/vet.webp'
import cat from '../images/cat.webp';


export default function VeterinaryPage2() {

    const features1 = [
        {
            question: "Significant cost savings",
            answer: "Our remote office staffing solutions help you save on overhead costs while maintaining high-quality service. Enjoy substantial financial benefits without compromising on the care your veterinary practice provides.",
        },
        {
            question: "Higher retention",
            answer: "With our remote staffing model, you can achieve higher employee satisfaction and retention. Happy staff leads to a more stable and dependable support system for your veterinary practice.",
        },
        {
            question: "After hours support",
            answer: "We offer comprehensive after-hours support to ensure that your veterinary practice is available to clients even beyond regular working hours. This service helps improve customer satisfaction and operational efficiency.",
        },
        {
            question: "Call tracking & monitoring",
            answer: "Our call tracking and monitoring services enhance your ability to manage and optimize client interactions. Gain valuable insights and ensure that every call is handled promptly and professionally.",
        },
    ];

    const features2 = [
        {
            name: 'Front desk support',
            description:
                'Patient scheduling, general phone support, EHR data entry',
            icon: ComputerDesktopIcon,
        },
        {
            name: 'Client recall & outbound services',
            description:
                'Client recall, vaccination reminders, prescription requests',
            icon: PhoneArrowUpRightIcon,
        },
        {
            name: 'Payment & collections',
            description:
                'Payment posting, billing inquiries and reminders, collections',
            icon: BanknotesIcon,
        },
        {
            name: 'Remote scribing',
            description:
                'Charting, visit documentation, and scribing virtually',
            icon: PencilIcon,
        },
    ];

    return (
        <MarketingPage
            vertical="veterinary"
            features1={features1}
            features2={features2}
            photo1={dog}
            photo2={vet1}
            photo3={dog2}
            photo4={cat}
        />
    )
}