import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Field, Label } from '@headlessui/react';

export default function ContactForm({ title, description, source, lead = false }) {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        country: 'US',
        phoneNumber: '',
    });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.company) newErrors.company = "Company is required";
        if (!formData.email) newErrors.email = "Email is required";
        if (!formData.phoneNumber) newErrors.phoneNumber = "Phone number is required";
        return newErrors;
    };


    const handleSubmit = async (e) => {
        setErrors({});
        setIsSubmitted(true);
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setIsSubmitted(false);
            return;
        }

        const body = {
            name: formData.name,
            email: formData.email,
            company: formData.company,
            phoneNumber: formData.phoneNumber,
            source: source,
            close: lead,
        };

        try {
            const response = await fetch('https://notifications.janiehealth.com', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            setFormData({
                name: '',
                company: '',
                email: '',
                country: 'US',
                phoneNumber: '',
            });
            if (lead && window.fbq) window.fbq('track', 'Lead');
        } catch (error) {
            setIsSubmitted(false);
            console.error('There was a problem with your fetch operation:', error);
        }
    };


    return (
        <div className="mx-auto max-w-xl text-center lg:text-left">
            <h2 className="text-2xl tracking-tight text-gray-900 sm:text-3xl font-semibold">
                {title}
            </h2>
            <p className="mt-2 text-sm leading-8 text-gray-600">
                {description}
            </p>
            <form onSubmit={handleSubmit} className="mx-auto mt-8 max-w-xl sm:mt-10">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div className="sm:col-span-2">
                        <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="mt-2.5">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                autoComplete="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-900 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {errors.name && <p className="text-sm text-red-500">{errors.name}</p>}
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                            Company
                        </label>
                        <div className="mt-2.5">
                            <input
                                id="company"
                                name="company"
                                type="text"
                                autoComplete="organization"
                                value={formData.company}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-900 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {errors.company && <p className="text-sm text-red-500">{errors.company}</p>}
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                            Email
                        </label>
                        <div className="mt-2.5">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-900 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {errors.email && <p className="text-sm text-red-500">{errors.email}</p>}
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="phoneNumber" className="block text-sm font-semibold leading-6 text-gray-900">
                            Phone number
                        </label>
                        <div className="relative mt-2.5">
                            <div className="absolute inset-y-0 left-0 flex items-center">
                                <label htmlFor="country" className="sr-only">
                                    Country
                                </label>
                                <select
                                    id="country"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-900 sm:text-sm"
                                >
                                    <option value="US">US</option>
                                    <option value="CA">CA</option>
                                    <option value="EU">EU</option>
                                </select>
                                <ChevronDownIcon
                                    aria-hidden="true"
                                    className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
                                />
                            </div>
                            <input
                                id="phoneNumber"
                                name="phoneNumber"
                                type="tel"
                                autoComplete="tel"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-900 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {errors.phoneNumber && <p className="text-sm text-red-500">{errors.phoneNumber}</p>}
                    </div>
                    <Field className="flex gap-x-4 sm:col-span-2">
                        <Label className="text-sm leading-6 text-gray-600">
                            By submitting this, I confirm that I agree to the
                            <a href="/privacy" target="_blank" className="font-semibold text-gray-900" onClick={(e) => e.stopPropagation()}>
                                &nbsp;Privacy&nbsp;Policy
                            </a>
                            .
                        </Label>
                    </Field>
                </div>
                <div className="mt-10">
                    {!isSubmitted &&
                        <button
                            type="submit"
                            className="block w-full rounded-md bg-gradient-to-b from-teal-800 to-teal-900 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-900"
                        >
                            Submit
                        </button>}
                    {isSubmitted && <p className="mt-4 text-teal-900">Thank you! We will reach out to you shortly.</p>}
                </div>
            </form>
        </div>
    );
}