import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

export default function Page404() {
    return (
        <div className="bg-white">
            {/* HEADER */}
            <Header />

            {/* 404 CONTENT */}
            <div className="flex flex-col items-center justify-center min-h-screen py-32 sm:py-40 lg:py-56">
                <div className="max-w-2xl px-6 text-center lg:px-8">
                    <h1 className="text-3xl font-heading font-regular tracking-tight text-gray-900 sm:text-5xl">
                        Oops! Page not found.
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        We can't seem to find the page you're looking for.
                        <br></br>It may have been moved or deleted.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link
                            to="/"
                            className="rounded-md bg-gradient-to-b from-teal-800 to-teal-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-900"
                        >
                            Go to Home
                        </Link>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <Footer />
        </div>
    )
}
