import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

import logo from '../images/logo-white.webp';

export default function MarketingPage({ vertical, features1, features2, photo1, photo2, photo3, photo4 }) {

    const stats = [
        {
            number: '$70K',
            description: 'Avg. savings per practice',
        },
        {
            number: '97%',
            description: 'Staff retention rate',
        },
        {
            number: '50+',
            description: 'Practices served',
        },
    ];

    return (
        <div className="bg-white">

            {/* HERO */}
            <div className="relative isolate overflow-hidden bg-teal-950">
                <img
                    src={photo1}
                    alt={vertical}
                    draggable="false"
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-bottom sm:object-left opacity-10"
                />
                <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
                        <img
                            alt="Janie Health"
                            src={logo}
                            draggable="false"
                            className="w-32 h-auto pb-40"
                        />

                        <h1 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                            Optimize your practice with virtual {vertical} assistants
                        </h1>
                        <p className="mt-6 text-2xl leading-8 text-gray-200">
                            Janie helps {vertical} practices reduce costs and enhance efficiency.
                        </p>
                    </div>
                    <div className="mx-auto mt-8 flex max-w-full sm:mt-16 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                        <div className="w-full flex-none sm:max-w-5xl lg:max-w-none">
                            <div className="-m-2 rounded-lg bg-white/95 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl px-4 py-8 mt-12 sm:p-12">
                                {/* CONTACT */}
                                <ContactForm
                                    title="Get in touch"
                                    description="Fill out the form and we'll reach out soon."
                                    source={`${vertical.toUpperCase()} Landing Page Lead Form`}
                                    lead={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* STATS */}
            <div className="relative isolate overflow-hidden bg-gradient-to-b from-teal-800 to-teal-900 py-24">
                <div className="flex justify-center">
                    <div className="mx-auto max-w-2xl lg:max-w-6xl">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-24 gap-y-16 lg:max-w-none lg:grid-cols-3">
                            {stats.map((stat) => (
                                <div key={stat.description} className="flex flex-col items-center">
                                    <dt className="flex flex-col items-center sm:text-4xl text-xl text-white">
                                        <div className="mb-2 sm:text-6xl text-5xl font-semibold">
                                            {stat.number}
                                        </div>
                                        <div className="text-base sm:text-xl">
                                            {stat.description}
                                        </div>
                                    </dt>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>


            {/* FEATURES */}
            <div className="overflow-hidden bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Why choose Janie</p>
                                <p className="mt-6 text-xl leading-8 text-gray-600">
                                    See why our customers are using Janie to help their practice run more efficiently.
                                </p>
                                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                    <div className="mx-auto max-w-7xl px-2">
                                        <div className="mx-auto max-w-4xl">
                                            <dl className="mt-10 space-y-6">
                                                {features1.map((feature) => (
                                                    <Disclosure key={feature.question} as="div" className="group rounded-lg">
                                                        <dt className="group-open:bg-transparent bg-gray-100 rounded-lg p-4">
                                                            <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                                                <span className="text-base font-semibold leading-7">{feature.question}</span>
                                                                <span className="ml-6 flex h-7 items-center">
                                                                    <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                                                                    <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                                                                </span>
                                                            </DisclosureButton>
                                                        </dt>
                                                        <DisclosurePanel as="dd" className="mt-2 pr-12">
                                                            <p className="text-base leading-7 text-gray-600">{feature.answer}</p>
                                                        </DisclosurePanel>
                                                    </Disclosure>
                                                ))}
                                            </dl>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <div className="flex items-center justify-end lg:order-first">
                            <img
                                alt={vertical}
                                draggable="false"
                                src={photo2}
                                className="w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-[48rem] rounded-lg shadow-xl"
                            />
                        </div>
                    </div>
                </div>
            </div>



            {/* TESTIMONIALS */}
            <section className="relative isolate overflow-hidden bg-gradient-to-b from-teal-800 to-teal-900 py-20 sm:py-36">
                <img
                    src={photo3}
                    alt={vertical}
                    draggable="false"
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-left opacity-10"
                />
                <div className="mx-auto max-w-7xl px-6 lg:px-8">

                    <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">

                        <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                            <figure className="mt-10 flex flex-auto flex-col justify-between">
                                <blockquote className="font-heading text-3xl leading-8 text-white">
                                    <p>
                                        “We've worked with two other {vertical} VA agencies and Janie is far and away the best.”
                                    </p>
                                </blockquote>
                                <figcaption className="mt-10 flex items-center gap-x-6">
                                    <div className="text-base">
                                        <div className="font-semibold text-white">Office Manager</div>
                                        <div className="mt-1 text-gray-400">CA</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="flex flex-col border-t border-white/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
                            <figure className="mt-10 flex flex-auto flex-col justify-between">
                                <blockquote className="font-heading text-3xl leading-8 text-white">
                                    <p>
                                        “Janie has helped us save thousands. We want at least 50% of our team to be from Janie over the next few months.”
                                    </p>
                                </blockquote>
                                <figcaption className="mt-10 flex items-center gap-x-6">
                                    <div className="text-base">
                                        <div className="font-semibold text-white">Practice Owner</div>
                                        <div className="mt-1 text-gray-400">FL</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            {/* FEATURES 2 */}
            <div className="overflow-hidden bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How we can support your practice</p>
                                <p className="mt-6 text-xl leading-8 text-gray-600">
                                    Janie staff are experienced, trained {vertical} professionals ready to support in a variety of roles.
                                </p>
                                <dl className="mt-10 max-w-xl space-y-8 text-base text-gray-600 lg:max-w-none">
                                    {features2.map((feature) => (
                                        <div key={feature.name} className="relative flex items-start space-x-4">
                                            <div className="flex-shrink-0">
                                                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gradient-to-b from-teal-800 to-teal-900">
                                                    <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                                                </div>
                                            </div>
                                            <div>
                                                <dt className="font-bold text-gray-900">{feature.name}</dt>
                                                <dd className="mt-1 text-gray-600">{feature.description}</dd>
                                            </div>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                        <img
                            alt={vertical}
                            src={photo4}
                            draggable="false"
                            className="w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-[48rem] rounded-lg shadow-xl"
                        />
                    </div>
                </div>
            </div>


            {/* FOOTER */}
            < Footer links={false} />
        </div>
    )
}