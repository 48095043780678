const navigation = {
    main: [
        { name: 'About', href: '/about' },
        { name: 'Careers', href: 'https://jobs.janiehealth.com' },
        { name: 'Trust & Security', href: 'https://trust.janiehealth.com' },
        { name: 'Terms of Service', href: '/terms' },
        { name: 'Privacy Policy', href: '/privacy' }
    ]
}

export default navigation;
