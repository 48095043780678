import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import logo from '../images/logo.webp';
import navigation from '../config/header-navigation';
import CookieConsent from "react-cookie-consent";

const Header = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <header className={`fixed inset-x-0 top-0 z-50 transition ${isScrolled ? 'bg-white shadow' : 'bg-transparent'} duration-300`}>
            <div className="mx-auto max-w-7xl">
                <div className="px-6 pt-6 sm:pb-8 pb-6 lg:pt-8 lg:max-w-2xl lg:pl-8 lg:pr-0">
                    <nav className="flex items-center justify-between lg:justify-start" aria-label="Global">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Janie</span>
                            <img
                                alt="Janie"
                                className="h-8"
                                src={logo}
                                draggable="false"
                            />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700 lg:hidden"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                        <div className="hidden lg:ml-12 lg:flex lg:gap-x-14">
                            {navigation.main.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className={`text-sm font-semibold leading-6 ${location.pathname === item.href ? 'text-teal-600' : 'text-gray-900'} hover:text-teal-600`}
                                >
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    </nav>
                </div>
            </div>
            <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="/" className="-m-1.5 p-1.5">
                            <img
                                className="h-8 w-auto"
                                src={logo}
                                alt="Janie"
                                draggable="false"
                            />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6">
                            <div className="space-y-2 py-6">
                                {navigation.main.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 ${location.pathname === item.href ? 'text-teal-600' : 'text-gray-900'} hover:bg-gray-50`}
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="mt-6">
                            <a
                                href="/contact"
                                className="rounded-md bg-gradient-to-b from-teal-800 to-teal-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-900"
                            >
                                Get started
                            </a>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
            <CookieConsent location="bottom"
                buttonText="Dismiss"
                style={{ background: "#134e4a", fontSize: "12px" }}
                buttonStyle={{ background: "white", color: "#134e4a", fontSize: "12px" }}
                expires={150}>
                We use cookies to enhance your experience on our site. By continuing to browse, you agree to the storing of cookies on your device.
            </CookieConsent>
        </header>
    );
};

export default Header;

