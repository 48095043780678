import Header from '../components/Header';
import Footer from '../components/Footer';

export default function TermsOfServicePage() {

    return (
        <div className="bg-white">

            {/* HEADER */}
            <Header />


            <div className="bg-white px-6 py-32 lg:px-8">
                <div className="mx-auto max-w-3xl text-base leading-7">
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-teal-900 sm:text-4xl">Terms of Service</h1>
                    <p className="mt-6 text-md leading-8">
                        Last updated: May 24, 2024
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>1. Acceptance of Terms</strong><br />
                        By accessing and using JanieHealth.com, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of the terms, you are prohibited from using this site.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>2. Use License</strong><br />
                        Health Sphere Innovations, LLC grants you a limited license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of Health Sphere Innovations, LLC.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>3. Disclaimer</strong><br />
                        The materials on JanieHealth.com are provided "as is". Health Sphere Innovations, LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>4. Limitations</strong><br />
                        In no event shall Health Sphere Innovations, LLC or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on JanieHealth.com, even if Health Sphere Innovations, LLC or a Health Sphere Innovations, LLC authorized representative has been notified orally or in writing of the possibility of such damage.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>5. Accuracy of Materials</strong><br />
                        The materials appearing on JanieHealth.com could include technical, typographical, or photographic errors. Health Sphere Innovations, LLC does not warrant that any of the materials on its website are accurate, complete, or current. Health Sphere Innovations, LLC may make changes to the materials contained on its website at any time without notice. However, Health Sphere Innovations, LLC does not make any commitment to update the materials.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>6. Links</strong><br />
                        Health Sphere Innovations, LLC has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Health Sphere Innovations, LLC of the site. Use of any such linked website is at the user's own risk.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>7. Modifications</strong><br />
                        Health Sphere Innovations, LLC may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>8. Governing Law</strong><br />
                        These terms and conditions are governed by and construed in accordance with the laws of Utah and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>Contact Information</strong><br />
                        If you have any questions about these Terms, please contact us at contact@janiehealth.com.
                    </p>
                </div>
            </div>

            {/* FOOTER */}
            <Footer />
        </div>
    )
}
