import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ContactForm from '../../components/ContactForm';

import DentistAdvisorsPartnership from '../../images/dentist-advisors.webp';

export default function DentistAdvisorsReferralPage() {

    const stats = [
        { id: 1, name: 'Significant cost savings', value: 'By only charging $10-12 per hour, clients reduce labor costs by 50%+' },
        { id: 2, name: 'Tailored onboarding', value: 'Partner with us to create a custom implementation plan for your practice' },
        { id: 3, name: 'Qualified professionals', value: 'Each Janie associate is a trained professional' },
        { id: 4, name: 'Higher retention', value: 'No more rehiring and retraining; our average retention rate is 97%' },
        { id: 5, name: 'Activity monitoring', value: 'Capture daily and weekly team activities via productivity monitoring' },
        { id: 6, name: 'Multilingual team', value: 'Our global team offers patient translation in 5+ languages' },
    ]

    return (
        <div className="bg-white">
            {/* HEADER */}
            < Header />

            {/* HERO */}
            <div className="mt-6 relative isolate overflow-hidden bg-white py-24 sm:py-10">
                <div className="px-6 py-10 sm:px-6 sm:py-32 lg:px-8">
                    <div className="mx-auto text-center">
                        <div className="flex justify-center py-12">
                            <img src={DentistAdvisorsPartnership} className="w-72 h-auto" alt="dentist-advisors" />
                        </div>
                        <h2 className="text-3xl tracking-tight text-teal-900 sm:text-5xl font-heading">
                            Janie has partnered with Dentist Advisors
                            <br className="hidden sm:inline"></br> to help meet your staffing requirements
                        </h2>
                        <p className="mx-auto mt-6 max-w-xl sm:text-lg leading-8 text-black">
                            Janie helps practices cut costs and enhance efficiency, all so that onsite staff can focus on what matters most: providing excellent care.
                        </p>
                    </div>
                </div>
            </div>

            {/* FORM */}
            <div className="isolate bg-white px-6 pt-6 pb-24 sm:pb-48 lg:px-36">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 h-full lg:items-center">

                    {/* CONTACT */}
                    <ContactForm
                        title="Get in touch with Dentist Advisors"
                        description="Fill out this form to learn more about Dentist Advisors."
                        source="Dentist Advisors Referral Form"
                    />

                    {/* INFO */}
                    <div className="h-auto max-w-xl">
                        <div className="mx-auto">
                            <h2 className="text-base font-semibold leading-8 text-teal-900">Low cost, high reward</h2>
                            <p className="mt-2 text-3xl font-heading tracking-tight text-gray-900 sm:text-4xl">
                                Our numerous client benefits
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Janie helps you significantly reduce overhead costs and increase staff retention, ensuring a stable and committed workforce, and promoting a healthier and more productive work environment for your practice.
                            </p>
                            <dl className="mt-16 grid max-w-xl grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-16">
                                {stats.map((stat) => (
                                    <div key={stat.id} className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6">
                                        {/* <dt className="text-sm leading-6 text-gray-600">{stat.value}</dt> */}
                                        <dd className="order-first text-2xl tracking-tight text-gray-900">{stat.name}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>

                </div>
            </div>



            {/* FOOTER */}
            < Footer />
        </div>
    )
}