import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import StaffingPage from './pages/StaffingPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import AboutPage from './pages/AboutPage';
import PricingPage from './pages/PricingPage';
import VeterinaryPage from './pages/VeterinaryPage';
import VeterinaryPage2 from './pages/VeterinaryPage2';
import DentistAdvisorsReferralPage from './pages/partners/DentistAdvisorsReferralPage';
import DentalIntellligenceReferralPage from './pages/partners/DentalIntelligenceReferralPage';
import Page404 from './pages/Page404';
import DermatologyPage from './pages/DermatologyPage';
import MedicalPage from './pages/MedicalPage';
import PodiatryPage from './pages/PodiatryPage';
import OptometryPage from './pages/OptometryPage';
import ContactPage from './pages/ContactPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/staffing" element={<StaffingPage />} />
        <Route path="/veterinary" element={<VeterinaryPage />} />
        <Route path="/veterinary2" element={<VeterinaryPage2 />} />
        <Route path="/dermatology" element={<DermatologyPage />} />
        <Route path="/medical" element={<MedicalPage />} />
        <Route path="/podiatry" element={<PodiatryPage />} />
        <Route path="/optometry" element={<OptometryPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/terms" element={<TermsOfServicePage />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/partners/dentist-advisors" element={<DentistAdvisorsReferralPage />} />
        <Route path="/partners/dental-intelligence" element={<DentalIntellligenceReferralPage />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
