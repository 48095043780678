import logo from '../images/logo.webp';
import navigation from '../config/footer-navigation';

const Footer = ({ links = true }) => {

    return (
        <footer className="bg-white">
            <div className="mx-auto max-w-7xl overflow-hidden px-6 pb-20 sm:pb-24 lg:px-8">
                <div className="flex justify-center">
                    <img alt="Janie" className="h-10" src={logo} draggable="false"/>
                </div>
                {links ?
                    (<nav className="flex flex-col justify-center items-center space-y-4 sm:space-y-0 sm:flex-row sm:justify-center sm:space-x-12 my-8" aria-label="Footer">
                        {navigation.main.map((item) => (
                            <div key={item.name} className="text-center">
                                <a href={item.href} className="text-sm font-semibold leading-6 text-gray-600 hover:text-gray-900">
                                    {item.name}
                                </a>
                            </div>
                        ))}
                    </nav>) : null}
                <p className="mt-10 text-center text-xs leading-5 text-gray-500">
                    &copy; {new Date().getFullYear()} Health Sphere Innovations, LLC.<br className="sm:hidden"></br>&nbsp;All rights reserved.
                </p>
            </div>
        </footer>
    )
};

export default Footer;