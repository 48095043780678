import { ComputerDesktopIcon, PhoneArrowUpRightIcon, BanknotesIcon, PencilIcon, InboxStackIcon } from '@heroicons/react/24/outline'
import MarketingPage from '../components/MarketingPage';

import photo1 from '../images/medical-11.webp';
import photo2 from '../images/optometry-1.webp';
import photo3 from '../images/medical-2.webp';
import photo4 from '../images/optometry-2.webp';


export default function OptometryPage() {

    const features1 = [
        {
            question: "Significant cost savings",
            answer: "Our remote office staffing solutions help your optometry practice save on overhead costs while maintaining high-quality service. Enjoy substantial financial benefits without compromising on patient care.",
        },
        {
            question: "Higher retention",
            answer: "With our remote staffing model, you can achieve higher employee satisfaction and retention. Content and dedicated staff lead to a more stable and reliable support system for your optometry practice.",
        },
        {
            question: "After hours support",
            answer: "We offer comprehensive after-hours support to ensure your optometry practice serves patients even beyond regular working hours. This enhances patient satisfaction and operational efficiency.",
        },
        {
            question: "Call tracking & monitoring",
            answer: "Our call tracking and monitoring services improve your capability to manage and optimize patient interactions. Gain valuable insights and ensure every call is handled promptly and professionally.",
        },
    ];

    const features2 = [
        {
            name: 'Front desk support',
            description:
                'Patient scheduling, general phone support, EHR data entry',
            icon: ComputerDesktopIcon,
        },
        {
            name: 'Client recall & outbound services',
            description:
                'Client recall, appointment reminders, prescription requests',
            icon: PhoneArrowUpRightIcon,
        },
        {
            name: 'Payment & collections',
            description:
                'Payment posting, billing inquiries and reminders, collections',
            icon: BanknotesIcon,
        },
        {
            name: 'Remote scribing',
            description:
                'Charting, visit documentation, and scribing virtually',
            icon: PencilIcon,
        },
        {
            name: 'Insurance coordinator',
            description:
                'Prior authorization, insurance verification, claims follow-up',
            icon: InboxStackIcon,
        },
    ];

    return (
        <MarketingPage
            vertical="optometry"
            features1={features1}
            features2={features2}
            photo1={photo1}
            photo2={photo2}
            photo3={photo3}
            photo4={photo4}
        />
    )
}